import { ReactNode } from 'react';
import { AuthGuard } from '../guards/AuthGuard';
import { AppLayout } from './AppLayout';
import { LogoOnlyLayout } from './LogoOnlyLayout';

interface Props {
  children: ReactNode;
  variant?: 'app' | 'logoOnly';
}

export default function Layout({ variant = 'app', children }: Props) {
  if (variant === 'logoOnly') {
    return <LogoOnlyLayout>{children}</LogoOnlyLayout>;
  }

  return (
    <AuthGuard>
      <AppLayout>{children}</AppLayout>
    </AuthGuard>
  );
}
