import Iconify from 'src/components/Iconify';
import { NavListProps } from 'src/components/nav-section/types';
import { PATH_APP } from 'src/paths';

export const navConfig: NavListProps[] = [
  {
    title: 'Dashboard',
    path: PATH_APP.root,
    icon: <Iconify icon="eva:pie-chart-outline" />,
  },
  {
    title: 'Inventar',
    path: PATH_APP.inventory.root,
    icon: <Iconify icon="eva:archive-outline" />,
    // children: [
    //   {
    //     title: 'Inventarliste',
    //     path: PATH_APP.inventory.root,
    //     icon: <Iconify icon="eva:archive-outline" />,
    //   },
    //   {
    //     title: 'Pakete',
    //     path: PATH_APP.bundles.root,
    //     icon: <Iconify icon="eva:gift-outline" />,
    //   },
    //   {
    //     title: 'Prämien',
    //     path: PATH_APP.bonuses.root,
    //     icon: <Iconify icon="eva:award-outline" />,
    //   },
    // ],
  },
  {
    title: 'Partner',
    path: PATH_APP.partners.root,
    icon: <Iconify icon="eva:people-outline" />,
  },
  {
    title: 'Verträge',
    path: PATH_APP.contracts.root,
    icon: <Iconify icon="eva:file-text-outline" />,
  },
  {
    title: 'Angebote',
    path: PATH_APP.offers.root,
    icon: <Iconify icon="eva:file-outline" />,
  },
  {
    title: 'Umsetzung',
    path: PATH_APP.tasks.root,
    icon: <Iconify icon="eva:checkmark-square-outline" />,
    isEnabled: (myAccount) => !!myAccount?.workflows.enabled,
  },
];
