import { Box } from '@mui/material';
import { IconButtonAnimate } from 'src/components/animate/IconButtonAnimate';
import Iconify from 'src/components/Iconify';

interface CollapseButtonProps {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
}

export function CollapseButton({ onToggleCollapse, collapseClick }: CollapseButtonProps) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'rotate(180deg)',
          }),
        }}
      >
        <Iconify icon="eva:arrow-ios-forward-outline" />
      </Box>
    </IconButtonAnimate>
  );
}
