import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, Divider, Drawer, List, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo } from 'src/components/Logo';
import { NavListProps, NavSectionProps } from 'src/components/nav-section/types';
import { NavList } from 'src/components/nav-section/vertical/NavList';
import Scrollbar from 'src/components/Scrollbar';
import { NAVBAR } from 'src/config';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { useResponsive } from 'src/hooks/useResponsive';
import { AccountMenu } from '../header/AccountMenu';
import { CollapseButton } from './CollapseButton';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

interface NavbarVerticalProps extends NavSectionProps {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
}

export function NavbarVertical({ data, isOpenSidebar, onCloseSidebar }: NavbarVerticalProps) {
  const { pathname } = useRouter();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        {!isCollapse ? <Logo /> : <Box height="36px" />}

        {!isCollapse && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
      </Stack>

      <Stack>
        <List disablePadding sx={{ px: 2 }}>
          {data.map((list: NavListProps) => (
            <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
          ))}
        </List>
      </Stack>

      {!isCollapse && (
        <>
          <Box flex={1} />
          <Stack direction="column" spacing={1} mt={2} px={3} fontSize="12px">
            <Divider />
            <AccountMenu />
          </Stack>
        </>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
