import { Stack } from '@mui/material';
import { hideScrollbarY } from 'src/utils/cssStyles';
import { NavSectionProps } from '../types';
import { NavList } from './NavList';

export function NavSectionHorizontal({ data, sx, ...other }: NavSectionProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mx: 'auto',
        ...hideScrollbarY,
        ...sx,
      }}
      {...other}
    >
      {data.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}
    </Stack>
  );
}
