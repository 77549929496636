import { useRouter } from 'next/router';

export function useActiveLink(path: string) {
  const { asPath } = useRouter();

  return {
    active: path.startsWith('#') || asPath.split('/')[1] === path.split('/')[1],
    isExternalLink: path.includes('http'),
  };
}
