import { ReactNode } from 'react';
import { LoadingScreen } from 'src/components/LoadingScreen';
import { useAuth } from 'src/contexts/AuthProvider';
import Login from 'src/pages/auth/login';
import { AccountSwitcher } from './AccountSwitcher';
import { StealLogin } from './StealLogin';

export function AuthGuard({ children }: { children: ReactNode }) {
  const { isInitialized, isAuthenticated } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <>
      {children}
      <StealLogin />
      <AccountSwitcher />
    </>
  );
}
