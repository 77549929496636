import { ReactNode } from 'react';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButtonAnimate } from 'src/components/animate/IconButtonAnimate';
import Iconify from 'src/components/Iconify';
import { Logo } from 'src/components/Logo';
import { HEADER, NAVBAR } from 'src/config';
import { useOffSetTop } from 'src/hooks/useOffSetTop';
import { useResponsive } from 'src/hooks/useResponsive';
import { bgBlur } from 'src/utils/cssStyles';
import { AccountPopover } from './AccountPopover';

interface RootStyleProps {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...bgBlur(),
  // boxShadow: 'none',
  height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
    }),
  },
}));

interface AppHeaderProps {
  children?: ReactNode;
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
}

export function AppHeader({
  children,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: AppHeaderProps) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <StyledAppBar isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 3 },
        }}
      >
        {isDesktop && verticalLayout && <Logo />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-fill" />
          </IconButtonAnimate>
        )}

        <Box flexGrow={1} />

        {children}

        <Box flexGrow={1} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
}
