import { useReactiveVar } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useMyAccountQuery } from 'src/api';
import Iconify from 'src/components/Iconify';
import { useAuth } from 'src/contexts/AuthProvider';
import { notFoundErrorVar } from 'src/utils/apollo-client';

export function AccountSwitcher() {
  const { accountId, switchAccount } = useAuth();
  const notFoundError = useReactiveVar(notFoundErrorVar);
  const myAccount = useMyAccountQuery().data?.myAccount;
  const otherAccounts = myAccount?.userAccounts?.filter((account) => account.id !== accountId) ?? [];
  const closeModal = () => notFoundErrorVar(null);

  if (!notFoundError || !otherAccounts.length) return;

  return (
    <Dialog open onClose={closeModal}>
      <DialogTitle>Falscher Account?</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Die Resource konnte nicht gefunden werden.
          <br />
          Sind Sie womöglich im falschen Account?
        </Typography>
        <Stack spacing={3} alignItems={otherAccounts.length === 1 ? 'center' : 'stretch'} mt={4}>
          {otherAccounts.map((account) => (
            <Button
              key={account.id}
              onClick={() => switchAccount(account.id)}
              endIcon={<Iconify icon="eva:arrow-forward-outline" />}
              variant="contained"
              size="large"
            >
              Wechseln zu {account.name}
            </Button>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={closeModal}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}
