import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Collapse } from '@mui/material';
import { useActiveLink } from 'src/hooks/useActiveLink';
import { NavListProps } from '../types';
import { NavItem } from './NavItem';

interface NavListRootProps {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
}

export function NavList({ data, depth, hasChild }: NavListRootProps) {
  const { pathname } = useRouter();
  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);
  const handleToggle = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!active) handleClose();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

interface NavListSubProps {
  data: NavListProps[];
  depth: number;
}

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={depth + 1} hasChild={!!list.children} />
      ))}
    </>
  );
}
